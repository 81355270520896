<template>
	<ul class="c-payments-row text-format-cbnk-1">
		<li class="c-payments-row__list-item">
			<div class="c-payments-row__card">
				<div class="c-payments-row__card-content text-format-cbnk-2">
					<p class="c-payments-row__card-title --size-xl">
						<span class="c-payments-row__card-title-value">0</span>
						<span class="c-payments-row__card-title-symbol">€</span>
					</p>
					<p class="c-payments-row__card-desc --size-m">
						<span class="c-payments-row__card-desc-subtitle">Sin comision</span>
						<span class="c-payments-row__card-desc-detail">
							de mantenimiento de la
							<br />
							cuenta
							<sup class="c-payments-row__card-sup">(1)</sup>
							.
						</span>
					</p>
				</div>
			</div>
		</li>
		<li class="c-payments-row__list-item">
			<div class="c-payments-row__card">
				<div class="c-payments-row__card-content text-format-cbnk-2">
					<p class="c-payments-row__card-title --size-xl">
						<span class="c-payments-row__card-title-value">0</span>
						<span class="c-payments-row__card-title-symbol">€</span>
					</p>
					<p class="c-payments-row__card-desc --size-m">
						<span class="c-payments-row__card-desc-subtitle">Sin comision</span>
						<span class="c-payments-row__card-desc-detail">
							de mantenimiento de la tarjeta de
							<br />
							débito
							<sup class="c-payments-row__card-sup">(2)</sup>
							.
						</span>
					</p>
				</div>
			</div>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'c-payments-row',
};
</script>

<style lang="scss" scoped>
.c-payments-row {
	display: flex;
	position: relative;
	width: 100%;
	flex-wrap: wrap;
	justify-content: center;
	gap: 80px;
	padding-bottom: 80px;
}

.c-payments-row__list-item {
	display: flex;
	position: relative;
	width: 170px;
	height: 140px;
	list-style: none;
	justify-content: center;
}

.c-payments-row__card {
	display: flex;
	position: relative;
	color: $color-primary;
}

.c-payments-row__card-content {
	display: grid;
	grid-template-rows: auto 1fr;
	justify-items: center;
	align-items: center;
	text-align: center;
}

.c-payments-row__card-title {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #e8e8e8;
	border-radius: 100%;
	height: 90px;
	width: 90px;
	font-size: 16px;
	font-size: 1rem;
	margin: 5px 0;
}

.c-payments-row__card-title-symbol {
	font-size: medium;
	margin-top: 15px;
}

.c-payments-row__card-title.--size-xl {
	font-size: 45px;
	font-size: 2.8rem;
}

.c-payments-row__card-desc-subtitle {
	display: block;
	font-size: 1.075rem;
	margin: 12px 0;
}

.c-payments-row__card-desc-subtitle.--inline {
	margin-bottom: -8px;
}

.c-payments-row__card-desc-detail {
	font-size: 0.75rem;
	font-weight: normal;
}

.c-payments-row__card-sup {
	font-size: 8px;
}

.c-payments-row__card-title-value {
	font-size: larger;
}

.c-payments-row__card-title-symbol {
	font-size: x-large;
	font-weight: 200;
}

@media (max-width: 576px) {
	.c-payments-row {
		padding: 18px;
		margin-bottom: 32px;
	}
}
</style>
