<template>
	<div class="c-commissions-row">
		<div class="c-commissions-row__container --column container">
			<p class="c-commissions-row__text font-weight-bold">INFORMACIÓN LEGAL</p>
			<ul class="c-commissions-row__list">
				<li class="c-commissions-row__list-item">
					Comisión de mantenimiento en la cuenta: 25€ trimestrales (1)
				</li>
				<li class="c-commissions-row__list-item">
					Comisión de mantenimiento en la tarjeta de débito: 15 € anual (2)
				</li>
			</ul>
			<p class="c-commissions-row__text">
				(1) y (2) 0 € de comisión de mantenimiento en la cuenta y 0€ de comisión de
				mantenimiento de la tarjeta de débito si cumples uno de estos requisitos:  
			</p>
			<ul class="c-commissions-row__list">
				<li class="c-commissions-row__list-item">
					Ser accionista de CBNK Banco de Colectivos S.A. y tener un número de acciones igual o
					superior a 500.
				</li>
				<li class="c-commissions-row__list-item">
					Que la suma de tus posiciones en CBNK Banco de Colectivos S.A. como titular en
					cuentas corrientes, depósitos a la vista, depósitos a plazo, fondos de inversión,
					valores, planes de pensiones, préstamos (importe pendiente de pago) y créditos
					(importe dispuesto) sea igual o superior a 100.000€.
				</li>
				<li class="c-commissions-row__list-item">
					Ser menor de 26 años. Este requisito solo aplicará en cuentas donde todos los
					titulares cumplan este requisito, si solo lo cumple un titular no se entenderá
					cumplido.
				</li>
			</ul>
			<p class="c-commissions-row__text">
				También podrás acceder a estas exenciones si cumples al menos dos de los siguientes
				requisitos:
			</p>
			<ul class="c-commissions-row__list">
				<li class="c-commissions-row__list-item">
					Ser graduado universitario de la rama de Ciencias de la Salud o Ingeniería.
				</li>
				<li class="c-commissions-row__list-item">Ser una comunidad de bienes en farmacia.</li>
				<li class="c-commissions-row__list-item">
					Tener una antigüedad en CBNK Banco de Colectivos S.A, como cliente igual o inferior a
					6 meses. Este requisito únicamente se cumplirá en tus primeros 6 meses como cliente.
				</li>
				<li class="c-commissions-row__list-item">
					Tener domiciliada la nómina, pensión, prestación por desempleo o SOE por un importe
					neto mensual igual o superior a 1.200€.
				</li>
				<li class="c-commissions-row__list-item">
					Que la suma de tus posiciones en CBNK Banco de Colectivos S.A. como titular en
					cuentas corrientes, depósitos a la vista, depósitos a plazo, fondos de inversión,
					valores y planes de pensiones sea igual o superior a 50.000€.
				</li>
				<li class="c-commissions-row__list-item">
					Que la suma de tus posiciones en CBNK Banco de Colectivos, S.A. como titular en
					préstamos (importe pendiente de pago) y créditos (importe dispuesto) sea igual o
					superior a 50.000€.
				</li>
				<li class="c-commissions-row__list-item">
					Que la suma de tus posiciones en CBNK Banco de Colectivos S.A. como titular en fondos
					de inversión o planes de pensiones sea igual o superior a 15.000€.
				</li>
				<li class="c-commissions-row__list-item">
					Tener domiciliados en cuentas de la entidad al menos 3 recibos al mes.
				</li>
				<li class="c-commissions-row__list-item">
					Ser titular de un TPV activo o de un servicio SmartCash Plus con máquina back
					activo. 
				</li>
			</ul>

			<p class="c-commissions-row__text">Ejemplos representativos:</p>
			<ul class="c-commissions-row__list">
				<li class="c-commissions-row__list-item">
					TAE Escenario cobro de la comisión de mantenimiento de cuenta 0 € / trimestre y la
					comisión de emisión/renovación de la tarjeta de débito 0€ / anuales:
				</li>
				Tipo interés acreedor del 0%. TAE = 0,00%. Total comisiones = 0 euros. Total interés = 0
				€
				<li class="c-commissions-row__list-item">
					TAE Escenario cobro de la comisión de mantenimiento de cuenta 25 € / trimestre y la
					comisión de emisión/renovación de la tarjeta de débito 15€ / anuales:
				</li>
				Tipo interés acreedor del 0%. TAE = -2,28%. Total comisiones =115 euros. Total interés =
				0 €* (*Calculadas siempre bajo un saldo de 5.000 euros)
			</ul>
			<p class="c-commissions-row__text">
				Consulte
				<a
					class="c-commissions-row__link"
					href="https://cbnk.es/dam/ASSETS-EXT/016-CANALES-DIGITALES/001-ONBOARDING/002-DOCUMENTOS/-X--DOC.-COMISIONES-CUENTA-TRANSPARENTE_BC.pdf"
					target="_blank"
				>
					AQUÍ
				</a>
				el documento informativo de comisiones.
			</p>

			<p class="c-commissions-row__text">
				(3) Disposición de efectivo en cajeros a débito: sin comisión en las 4 primeras
				disposiciones al mes en cualquier cajero de España, sin límite de importe. A partir del
				5º reintegro, para cualquier importe, se cobrará la comisión que cobre la entidad
				propietaria del cajero.
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'c-commissions-row',
};
</script>

<style lang="scss" scoped>
.c-commissions-row {
	display: flex;
	width: 100%;
}

.c-commissions-row__container {
	color: $color-secondary;
	display: flex;
	padding: 10px;
	font-family: 'Figtree';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 1.2;
}

.c-commissions-row__container.--column {
	flex-direction: column;
}

.c-commissions-row__text {
	padding-bottom: 8px;
}

.c-commissions-row__list {
	list-style-position: inside;
	padding-bottom: 8px;
	margin-left: 10px;
}

.c-commissions-row__link {
	color: $color-secondary;
	text-decoration: underline;
}
</style>
