<template>
	<div class="c-header">
		<div class="c-header__header --wrap">
			<div class="c-header__header-content">
				<div class="c-header__header-logo">
					<img
						src="@project/assets/img/logo-claim.svg"
						alt=""
						width="100%"
						height="100%"
					/>
				</div>
				<div class="c-header__header-product-risk">
					<c-product-risk />
				</div>
			</div>
		</div>
		<div class="c-header__body text-format-cbnk-1">
			<div class="c-header__container --space-between container-cbnk">
				<div class="c-header__column --translateY">
					<div class="c-header__content">
						<h1 class="c-header__banner">Cuenta Transparente</h1>

						<h1 class="c-header__title">
							Bienvenidos a la
							<br />
							Cuenta Transparente
						</h1>

						<p class="c-header__desc-1">
							En CBNK trabajamos día a día para acompañarte y sabemos
							<br />
							que la única forma de conseguirlo es siento totalmente
							<br />
							transparentes contigo, claros y sin dobleces.
							<br />
							Así somos, y así es nuestra Cuenta Transparente.
						</p>
					</div>
				</div>
				<div class="c-header__column --no-mobile">
					<div class="c-header__form">
						<div class="c-header__form-container">
							<c-landing-form
								:isButtonDisabled="isButtonDisabled"
								@submit="submit"
							/>
						</div>
					</div>
					<div class="c-header__process-info">
						<c-process-info />
					</div>
				</div>
			</div>
			<div class="c-header__floating">
				<button
					class="c-header__floating-button"
					:disabled="isButtonDisabled"
					@click="openRegisterFormModal"
				>
					Comenzar
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import DeviceDetector from 'device-detector-js';
import CProductRisk from '@components/c-cbnk-product-risk';
import CLandingForm from '@components/c-cbnk-landing-form';
import CProcessInfo from '@project/components/c-process-info';
import MAdvertisement from '@modals/m-advertisement';
import {
	PENDING_ONBOARDING,
	USER_ALREADY_CLIENT,
	MESSAGE_PENDING_ONBOARDING,
	MESSAGE_USER_ALREADY_CLIENT,
} from '@modules/service/constants';

const VERTICAL_ERROR = 'C404000254';

export default {
	name: 'c-header',

	components: {
		CProductRisk,
		CLandingForm,
		CProcessInfo,
	},

	data() {
		return { isButtonDisabled: false };
	},

	methods: {
		submit(data) {
			this.isButtonDisabled = true;

			const { device } = new DeviceDetector().parse(navigator.userAgent);
			const { [process.env.VUE_APP_CURRENT_PROJECT]: entityId } = {
				cbnk: '0234',
			};
			// eslint-disable-next-line no-shadow
			const { name, mobilePhoneNumber, email } = data;
			const urlParams = new window.URLSearchParams(window.location.search);
			const utm = {
				source: urlParams.get('utm_source'),
				medium: urlParams.get('utm_medium'),
				campaign: urlParams.get('utm_campaign'),
				term: urlParams.get('utm_term'),
				content: urlParams.get('utm_content'),
			};
			const channelId = urlParams.get('channel') || 'e6cae6ed-078a-4581-bdaf-54b8481eab66';
			const subchannelId = urlParams.get('subchannel');

			const partner = urlParams.get('P') || urlParams.get('p');
			const officeId = urlParams.get('O') || urlParams.get('o');
			const vertical = urlParams.get('V') || urlParams.get('v');
			const collective = urlParams.get('C') || urlParams.get('c');

			if (vertical) {
				this.$store.dispatch('setVertical', vertical);
			}

			if (collective) {
				this.$store.dispatch('setCollective', collective);
			}

			if (officeId) {
				this.$store.dispatch('setOfficeSelected');
			}
			this.$store
				.dispatch('onboarding/createProcess', {
					entityId,
					device: {
						brand: device?.brand,
						model: device?.model,
					},
					name,
					mobilePhoneNumber,
					email,
					utm,
					channelId,
					subchannelId,
					origin: this.$store.state.onboarding.productId,
					acceptsTgss: false,
					pendingSearch: false,
					partner,
					officeId,
					vertical,
					collective,
				})
				.then(() => {
					this.$router.push('app-personal-data');
				})
				.catch((err) => {
					const errorCode = err?.response?.data?.errorCode;
					if (errorCode === PENDING_ONBOARDING) {
						return this.$store.dispatch('modal/open', {
							component: MAdvertisement,
							props: { text: MESSAGE_PENDING_ONBOARDING, isMobileContact: false },
						});
					}

					if (errorCode === USER_ALREADY_CLIENT) {
						return this.$store.dispatch('modal/open', {
							component: MAdvertisement,
							props: { text: MESSAGE_USER_ALREADY_CLIENT, isMobileContact: false },
						});
					}

					if (errorCode === VERTICAL_ERROR) {
						this.$store.dispatch('setVertical', null);
						this.$store.dispatch('setCollective', null);
						this.$router.push('app-personal-data');
					}
				})
				.finally(() => {
					this.isButtonDisabled = false;
				});
		},

		async openRegisterFormModal() {
			const data = await this.$store.dispatch('modal/open', {
				component: MRegisterForm,
				props: { fullscreen: true },
			});

			if (data) {
				this.submit(data);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.c-header {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.c-header__body {
	display: flex;
	width: 100%;
	background-image: url(~@project/assets/img/cbnk-landing.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	border-bottom-left-radius: 25% 45%;
	justify-content: space-evenly;
}

.c-header__header {
	display: flex;
	position: relative;
	width: 100%;
	background-color: $color-surface-light;
	justify-content: space-around;
}

.c-header__header.--wrap {
	flex-wrap: wrap;
}

.c-header__header-content {
	display: flex;
	flex-direction: column;
	padding: 18px;
}

.c-header__header-logo {
	display: flex;
	position: relative;
	width: 375px;
	height: 49px;
}

.c-header__header-product-risk {
	max-width: 670px;
}

.c-header__container {
	display: flex;
	padding: 18px;
	gap: 440px;
}

.c-header__header-logo {
	display: flex;
	position: relative;
	width: 375px;
	height: 49px;
}

.c-header__header-product-risk {
	max-width: 670px;
}

.c-header__content.--wrap {
	flex-wrap: wrap;
}

.c-header__column.--translateY {
	transform: translateY(160px);
}

.c-header__column {
	display: flex;
	flex-direction: column;
}

.c-header__content {
	padding-right: 10px;
}

.c-header__banner {
	color: $color-surface-light;
	display: flex;
	width: max-content;
	font-size: 1.3rem;
	font-weight: 600;
	text-align: left;
}

.c-header__title {
	color: $color-surface-light;
	font-size: 52px;
	font-size: 3.25rem;
	font-weight: 100;
	margin: 46px 0 32px;
}

.c-header__outstandings {
	margin-top: 20px;
}

.c-header__desc-1,
.c-header__desc-2 {
	color: $color-surface-light;
	font-weight: 400;
	font-size: 18px;
	font-size: 1.125rem;
}

.c-header__desc-2 {
	margin-bottom: 40px;
}

.c-header__form,
.c-header__process-info {
	display: flex;
	position: relative;
	width: 400px;
}

.c-header__form {
	padding-bottom: 10px;
}

.c-header__form-container {
	border-radius: 10px;
	padding: 12px 24px;
	background: $color-surface-light;
}

.c-header__floating {
	display: none;
	position: fixed;
	width: 100%;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.c-header__floating-button {
	color: $color-surface-light;
	outline: none;
	appearance: none;
	user-select: none;
	touch-action: manipulation;
	white-space: nowrap;
	display: flex;
	width: 100%;
	background: $color-primary;
	border: 0;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	text-transform: uppercase;
	justify-content: center;
	align-items: center;
	padding: 16px 12px;
}

@media (min-width: 1620px) {
	.c-header__header-content {
		display: inline-flex;
		flex-direction: row;
		gap: 540px;
		padding: 18px;
		align-items: center;
		margin-left: auto;
	}
}

@media (max-width: 1620px) and (min-width: 992px) {
	.c-header__body {
		border-bottom-left-radius: 0;
	}

	.c-header__header-content {
		flex-direction: row;
		width: 100%;
		justify-content: space-around;
		align-items: center;
	}

	.c-header__container {
		flex-grow: 1;
		gap: 0;
		justify-content: space-around;
	}
}

@media (max-width: 992px) {
	.c-header__header-content {
		gap: 25px;
	}

	.c-header__title {
		margin: 0;
	}

	.c-header__column.--translateY {
		transform: translateY(0);
	}

	.c-header__container {
		padding: 64px;
	}

	.c-header__container.--translateY {
		margin-top: -80px;
	}

	.c-header__banner {
		display: none;
	}

	.c-header__column.--no-mobile {
		display: none;
	}

	.c-header__floating {
		display: flex;
	}
}

@media (max-width: 576px) {
	.c-header__header-content {
		align-items: center;
	}

	.c-header__header-logo {
		max-width: 280px;
	}

	.c-header__content {
		padding: 0px;
	}

	.c-header__container {
		padding: 46px;
	}

	.c-header__title {
		font-size: 1.6rem;
		margin-bottom: 30px;
	}

	.c-header__desc-1 {
		font-size: 1rem;
	}
}
</style>
