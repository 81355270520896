// La sesión ha expirado y necesita una nueva clave par.
export const UUID_EXPIRED = 'C401000101';

//El usuario tiene un onboarding en vuelo
export const PENDING_ONBOARDING = 'C409000353';

//El usuario ya es un cliente
export const USER_ALREADY_CLIENT = 'C403000352';

//Mensaje de error para el caso de que el usuario tenga un onboarding en vuelo
export const MESSAGE_PENDING_ONBOARDING =
	'Hemos visto que tienes un proceso de alta en curso y estamos gestionándolo. Si necesitas más información contacta con nosotros en ';

//Mensaje de error para el caso de que el usuario ya sea un cliente
export const MESSAGE_USER_ALREADY_CLIENT =
	'Parece que ya hemos tenido contacto contigo en algún momento. Para realizar la gestión con nosotros puedes ponerte en contacto con nosotros en:  ';
