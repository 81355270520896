<template>
	<div
		@click="decreaseStep"
		class="d-flex align-items-center justify-content-center backStep"
	>
		<div class="mb-2"></div>
		<p>Atrás</p>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'back-step',

	props: {
		stepNumber: {
			type: Number,
		},
	},

	computed: {
		...mapState('onboarding', ['step', 'hasDoneIdentification']),
	},

	methods: {
		async decreaseStep() {
			const beforeStep = this.stepNumber - 1;

			if (beforeStep === 6 && this.hasDoneIdentification) {
				await this.$store.dispatch('onboarding/decreaseStep', 3);
			} else {
				await this.$store.dispatch('onboarding/decreaseStep', 1);
			}

			switch (beforeStep) {
				case 1:
					this.$router.push({ name: 'app-personal-data' });
					break;
				case 2:
					this.$router.push({ name: 'app-address' });
					break;
				case 3:
					this.$router.push({ name: 'app-professional-data' });
					break;
				case 4:
					this.$router.push({ name: 'app-gdpr-consent' });
					break;
				case 5:
					this.$router.push({ name: 'app-choose-options' });
					break;
				case 6:
					if (this.hasDoneIdentification) {
						this.$router.push('app-gdpr-consent');
					} else {
						this.$router.push({ name: 'app-camera-capture' });
					}
					break;
				case 7:
					this.$router.push({ name: 'app-election-office' });
					break;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.backStep {
	position: absolute;
	top: 10px;
	height: 100px;
	width: 100px;
	gap: 10px;
}
.backStep div {
	height: 16px;
	width: 16px;
	background-image: url(~@/assets/images/icons-back.svg);
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
}
.backStep p {
	font-size: 14px;
	font-weight: 500;
}

@media screen and (max-width: 767px) {
	.backStep {
		top: -30px;
		width: 100px;
		justify-content: none;
	}
}
</style>
