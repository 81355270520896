<template>
	<div>
		<div class="mx-4 mb-5 blockCall">
			<div class="d-flex callHelp align-items-center">
				<img
					alt
					src="@/assets/images/call.svg"
				/>
				<p>
					Llámanos al
					<span class="tfLinkBC">{{ telephone }},</span>
					si tienes cualquier duda.
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'app-mobile-call',

	computed: {
		telephone({ $store }) {
			if ($store.state.app.vertical == 2) {
				return '900 101 817';
			}
			return '900 107 411 / 913 109 550';
		},
	},
};
</script>

<style lang="scss" scoped>
.callHelp {
	height: 30px;
}
.callHelp img {
	width: 32px;
	height: 26px;
	margin-right: 10px;
}

@media screen and (min-width: 480px) {
	.blockCall {
		display: none;
	}
}
</style>
