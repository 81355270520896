<template>
	<div class="m-consent-tgss">
		<div class="modal-header">
			<button
				data-test-id="close-button"
				@click="closeModal"
				aria-label="Close"
				class="close"
				type="button"
			>
				<span aria-hidden="true">×</span>
			</button>
		</div>
		<div class="contact-support-info text-format-2x">
			<p>
				{{ text }}
				<a
					class="text-format-3 text"
					:href="`mailto:correo@cbnk.es`"
					v-if="!isMobileContact"
				>
					correo@cbnk.es
				</a>
			</p>
			<div
				v-if="isMobileContact"
				class="contact-support-info_mobile"
			>
				<span class="text-format-3 text">913 42 68 03 / 900 107 411</span>
				<a
					class="text-format-3 text"
					:href="`mailto:correo@cbnk.es`"
				>
					correo@cbnk.es
				</a>
				<a
					class="text-format-3 text"
					href="https://www.cbnk.es/personas"
					target="_blank"
				>
					www.cbnk.es
				</a>
			</div>
		</div>
		<div class="modal-footer justify-content-center">
			<button
				data-test-id="confirm-button"
				class="button-confirm txt-uc"
				type="button"
				@click="closeModal"
			>
				ACEPTAR
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'm-consent-tgss',

	props: { text: String, isMobileContact: Boolean },

	methods: {
		closeModal() {
			this.$store.dispatch('modal/close');
		},
	},
};
</script>

<style lang="scss" scoped>
.modal-header button {
	outline: none;
}

.contact-support-info {
	max-height: 40vh;
	padding: 0 3rem;
	overflow-x: auto;
}

.contact-support-info p:not(:first-of-type) {
	margin-top: 10px;
}

.modal-footer {
	padding: 2rem;
}

.text {
	font-size: 14px;
}

.contact-support-info_mobile {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 10px;
}
</style>
